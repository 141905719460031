import {
  Directive,
  Output,
  EventEmitter,
  ElementRef,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Subject, debounceTime } from 'rxjs';

@Directive({
  selector: '[appHeightObserver]',
  standalone: true,
})
export class HeightObserverDirective implements OnInit, OnDestroy {
  @Output() heightChange = new EventEmitter<number>();

  private observer: ResizeObserver;
  private lastHeight = 0;
  private heightSubject = new Subject<number>();
  private threshold = 10; // 10px threshold to ignore minor fluctuations

  constructor(private elementRef: ElementRef) {
    // set up the height change stream with debounce
    this.heightSubject
      .pipe(
        debounceTime(100), // wait for height to stabilize
      )
      .subscribe((height) => {
        this.heightChange.emit(height);
      });

    this.observer = new ResizeObserver((entries) => {
      const newHeight = Math.round(entries[0].contentRect.height);
      if (Math.abs(newHeight - this.lastHeight) > this.threshold) {
        this.lastHeight = newHeight;
        this.heightSubject.next(newHeight);
      }
    });
  }

  ngOnInit() {
    this.observer.observe(this.elementRef.nativeElement);
  }

  ngOnDestroy() {
    this.observer.disconnect();
    this.heightSubject.complete();
  }
}
