import { IframeMessage } from '../types/iframe-message';
export function emitIframeMessage(type, payload = {}) {
  const messageData = {
    type,
    ...payload
  };
  if (type === IframeMessage.UpdateHeight) {
    postMessage(messageData);
    // for legacy client compatibility
    console.log('emit iframe legacy message', `starter-app:height#${payload.heightInPx}`);
    window.parent.postMessage(`starter-app:height#${payload.heightInPx}`, '*');
  } else {
    postMessage(messageData);
  }
}
function postMessage(message) {
  console.log('emit iframe message', message);
  window.parent.postMessage(message, '*');
}